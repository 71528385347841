export default [
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/servidor/login/logout-view'),
    meta: {
      auth: true,
      body: 'bg-light',
    },
  },
  {
    path: '/session-expired',
    name: 'session-expired',
    component: () => import('@/components/main/login/session-expired'),
    meta: {
      auth: true,
      body: 'bg-light',
    },
  },
]
