import Vue from 'vue'

// import './components/main'
import './styles/base.scss'
import './components/primevue'

import { createPinia, PiniaVuePlugin } from 'pinia'
import servidor from './router/servidor'
//import vazio from './router/vazio.js'

import App from './App.vue'
import auth from './plugins/auth'
import html from './plugins/html'
import http from './plugins/http'
import i18n from './i18n'

import authentication from './plugins/authentication'
import authModule from '@/modules/auth'

export const bus = new Vue()

Vue.config.productionTip = false
Vue.config.devtools = true

Vue.use(auth)
Vue.use(http)
Vue.use(html)
Vue.use(PiniaVuePlugin)
Vue.use(require('vue-moment'))

const pinia = createPinia()
const host = window.location.host
const parts = host.split('.')
const router = () => {
  let routes
  switch (parts[0]) {
    case 'servidor':
      routes = servidor
      break
    default:
      routes = servidor
      break
  }
  return routes
}

Vue.use(authentication)

Vue.$keycloak
  .init({ onLoad: 'check-sso', checkLoginIframe: false })
  .then(() => {
    setInterval(async () => {
      // Se refresh_token é nulo, não chamar o updateToken para não
      // redirecionar da home do PBConsig para a tela de login do SSO
      if (authModule.refresh_token != null) {
        authModule.updateToken(
          window.location.origin + window.location.pathname,
          function () {},
        )
      }
    }, 50000)

    new Vue({
      router: router(),
      i18n,
      pinia,
      render: (h) => h(App),
    }).$mount('#app')
  })
