export default [
  {
    path: '/',
    component: () => import('@/views/servidor/App.vue'),
    meta: {
      auth: true,
    },
    children: [
      {
        path: '',
        name: 'painel',
        component: () => import('@/views/servidor/painel/PainelServidor.vue'),
      },
      {
        path: 'historico-funcional',
        name: 'historico-funcional',
        component: () =>
          import('@/views/servidor/historicoFuncional/HistoricoFuncional.vue'),
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/servidor/dashboard.vue'),
      },
      {
        path: 'dados-pessoais',
        name: 'dados-pessoais',
        component: () =>
          import('@/views/servidor/servidor/ServidorDadosPessoais.vue'),
      },
      {
        path: 'dados-cadastrais',
        name: 'dados-cadastrais',
        component: () =>
          import('@/views/servidor/servidor/ServidorDadosCadastrais.vue'),
      },
      {
        path: 'dependentes',
        name: 'dependentes',
        component: () =>
          import('@/views/servidor/servidor/ServidorDependentes.vue'),
      },
      {
        path: 'ficha-financeira',
        name: 'ficha-financeira',
        component: () =>
          import('@/views/servidor/servidor/ServidorFichaFinanceira.vue'),
      },
      {
        path: 'codigo-etica',
        name: 'codigo-etica',
        component: () => import('@/views/servidor/servidor/CodigoEtica.vue'),
      },
      {
        path: 'dados-funcionais',
        name: 'dados-funcionais',
        component: () =>
          import('@/views/servidor/servidor/ServidorDadosFuncionais.vue'),
      },
      {
        path: 'contra-cheque',
        name: 'contrachequeMenu',
        component: () =>
          import('@/views/servidor/contracheque/ContrachequeMenu.vue'),
        children: [
          {
            path: 'lista',
            name: 'ContraChequeLista',
            component: () =>
              import('@/views/servidor/contracheque/ContrachequeIndex.vue'),
          },
          {
            path: 'rendimentos',
            name: 'rendimentos',
            component: () =>
              import('@/views/servidor/contracheque/RendimentosList.vue'),
          },
        ],
      },
      {
        path: '/detalhes',
        name: 'contrachequeDetalhes',
        component: () =>
          import('@/views/servidor/contracheque/ContrachequeDetalhe.vue'),
        props: (route) => ({
          id: route.query.id,
          matriculaId: route.query.matriculaId,
        }),
      },
      {
        path: 'servicosDigitais',
        name: 'servicosDigitais',
        component: () =>
          import('@/views/servidor/servicosDigitais/ServicoDigitalTab.vue'),
      },
      {
        path: 'servicoDetalhes/:id',
        name: 'servicoDigitalDetalhe',
        component: () =>
          import('@/views/servidor/servicosDigitais/ServicoDigitalDetalhe.vue'),
      },
    ],
  },
  {
    path: '/validacaoContracheque',
    component: () =>
      import('@/views/servidor/contracheque/ValidacaoContracheque.vue'),
    props: (route) => ({
      codigo: route.query.codigo,
      matricula: route.query.matricula,
      bruto: route.query.bruto,
    }),
  },
  {
    path: '/validacaoFichaFinanceira',
    component: () =>
      import('@/views/servidor/servidor/ValidacaoFichaFinanceira.vue'),
    props: (route) => ({
      codigo: route.query.codigo,
      matricula: route.query.matricula,
      entidade: route.query.entidade,
      exercicio: route.query.exercicio,
    }),
  },
]
