import CryptoJS from 'crypto-js'
import events from './events'

const dekey = 'chave'

export default {
  get(key) {
    const val = localStorage.getItem(key)
    if (val != null) {
      var bytes = CryptoJS.AES.decrypt(val, dekey)
      try {
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      } catch (error) {
        // console.log(error)
      }
      return /^(\{|\[).*(\}|\])$/.test(decryptedData)
        ? JSON.parse(decryptedData)
        : decryptedData
    }
    return null
  },

  set(key, val) {
    let value

    if (val instanceof Date) value = val.toJSON()
    else if (typeof val === 'object') value = JSON.stringify(val)
    else value = val
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value),
      dekey,
    ).toString()

    // let teste = 'U2FsdGVkX1//dSCs+t6voq4F2DTusuSyD1yhcX40uOQ='
    // var bytes = CryptoJS.AES.decrypt(c, 'chave')
    // var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))

    // console.log(decryptedData)

    localStorage.setItem(key, encrypted)
    events.$emit('storage:set', key, val)

    return value
  },

  remove(key) {
    events.$emit('storage:remove', key)
    return localStorage.removeItem(key)
  },

  clear() {
    events.$emit('storage:clear')
    return localStorage.clear()
  },
}
