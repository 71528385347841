import Vue from 'vue'
import Keycloak from 'keycloak-js'
import Config from '@/config'

const options = {
  url: `${Config.SSO_URL}`,
  realm: Config.REALM_PB,
  clientId: Config.CLIENT_ID,
}

const _keycloak = new Keycloak(options)

const Plugin = {
  install(Vue) {
    Vue.$keycloak = _keycloak
  },
}

Plugin.install = (Vue) => {
  Vue.$keycloak = _keycloak
  Object.defineProperties(Vue.prototype, {
    $keycloak: {
      get() {
        return _keycloak
      },
    },
  })
}

Vue.use(Plugin)

export default Plugin
