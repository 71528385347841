<template>
  <div id="app">
    <!-- 
      <div v-if="ambiente" class="ambiente noprint">
      <span class="ambiente-info" :class="ambientecor">{{ ambiente }} </span>
    </div>
    -->
    <router-view />
    <Toast />
    <ConfirmDialog />
  </div>
</template>

<script>
import Vue from 'vue'
import Moment from 'moment'
import events from '@/modules/events'
import Config from '@/config'

Vue.filter('formatarData', function (value) {
  if (
    value == null ||
    typeof value === 'undefined' ||
    value == '' ||
    value == 'Não Consta'
  ) {
    return '-'
  } else {
    return Moment(value).format('DD/MM/YYYY')
  }
})

Vue.filter('formatarValor', function (value) {
  if (value == null || typeof value === 'undefined' || value == '') {
    return '-'
  } else {
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })
  }
})

Vue.filter('formatarValorRelatorio', function (value) {
  if (value == null || typeof value === 'undefined' || value == '') {
    return '-'
  } else {
    return value.toLocaleString('pt-br', { minimumFractionDigits: 2 })
  }
})

Vue.filter('formatarSexo', function (value) {
  const sexoMap = {
    M: 'Masculino',
    F: 'Feminino',
    null: 'Não Consta',
    undefined: 'Não Consta',
    '': 'Não Consta',
  }
  return sexoMap[value] || 'Outro'
})

Vue.filter('formatarEstadoCivil', function (value) {
  const sexoMap = {
    C: 'Casado(a)',
    S: 'Solteiro(a)',
    D: 'Divorciado(a)',
    null: 'Não Consta',
    undefined: 'Não Consta',
    '': 'Não Consta',
  }
  return sexoMap[value] || 'Outro'
})

Vue.filter('formatarEntidade', function (value) {
  if (value == 'SEAD 1') {
    return 'Executivo'
  } else if (value == 'SEAD 2') {
    return 'PBPrev'
  } else {
    return value
  }
})

Vue.filter('limitadorString', function (value) {
  if (value.length >= 20) {
    return value.substring(0, 20) + '...'
  }
  return value
})

Vue.filter('mascaraCpf', function (value) {
  if (value) {
    let cpf = value?.replace(/[^\d]/g, '')
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  }
  return ''
})

export default {
  name: 'App',

  computed: {
    loading: () => events.loading,
    ambiente: () => {
      if (Config.ENV === 'production') return null
      return Config.ENV
    },
    ambientecor: () => {
      if (Config.ENV === 'development') return 'ambiente-desenvolvimento'
      if (Config.ENV === 'homolog') return 'ambiente-homologacao'
      return 'ambiente-padrao'
    },
    versao: () => {
      return Config.TAG_GIT
    },
    data_publicacao: () => {
      return Config.DATA_PUBLICACAO
    },
  },
}
</script>

<style lang="scss" scoped>
.logo {
  width: 100%;
  margin: auto;
  display: block;
}

.footer {
  background-color: #071d41;
  margin-top: 5%;
  width: 100vw;
}
.bar {
  background-color: #ffffff;
}

.logo {
  margin-left: 37px;
}

#app {
  min-height: 100vh;
}
.logo {
  z-index: 9999;
  position: fixed;
}
.ambiente-info {
  text-align: center;
  width: 100%;
  z-index: 9999;
  position: fixed;
  margin: 0 auto;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
}

.ambiente-desenvolvimento {
  background: rgb(255, 0, 0);
  color: white;
}

.ambiente-homologacao {
  background: rgb(255, 255, 0);
  color: black;
}

.ambiente-padrao {
  background: rgb(0, 0, 0);
  color: white;
}

@media print {
  .noprint {
    display: none;
  }
}
</style>
